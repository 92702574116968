import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
// import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import Logo from "../../assets/img/logo.png";

import { SidebarItemsType } from "../../types/sidebar";
import { Button, Form, InputGroup } from "react-bootstrap";
import { t } from "i18next";
import { Search } from "lucide-react";

interface SidebarProps {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  open?: boolean;
  showFooter?: boolean;
}

const Sidebar = ({ items, showFooter = true }: SidebarProps) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          {/* <Form className="d-none d-sm-inline-block">
            <InputGroup className="input-group-navbar">
              <Form.Control
                placeholder={t("Search") as string}
                aria-label="Search"
              />
              <Button variant="">
                <Search className="lucide" />
              </Button>
            </InputGroup>
          </Form>    */}
          <SidebarNav items={items} />
          {/* {!!showFooter && <SidebarFooter />} */}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
