import { useTranslation } from "react-i18next";
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";

import Logo from "../../assets/img/logo.png";
import tbLogo from "../../assets/img/ted_baker.png";
import dcLogo from "../../assets/img/digicape_logo_black.png";

import useSidebar from "../../hooks/useSidebar";
import { Logout } from "../auth/Logout";
import { useAuth, UserDetail } from "../../contexts/AuthContext";
import { useEffect } from "react";

// import NavbarDropdown from "./NavbarDropdown";
// import NavbarDropdownItem from "./NavbarDropdownItem";
// import NavbarLanguages from "./NavbarLanguages";
// import NavbarThemeToggle from "./NavbarThemeToggle";
// import NavbarUser from "./NavbarUser";

const NavbarComponent = () => {
	
	const { t } = useTranslation();
	const { isOpen, setIsOpen } = useSidebar();
	const {userDetail}  = useAuth();

	let customerLogo = Logo;

	if(process.env.REACT_APP_COMPANY === "ares") {
		customerLogo = tbLogo;
	}

	if(process.env.REACT_APP_COMPANY === "digicape") {
		customerLogo = dcLogo;
	}

	useEffect(() => {
		// console.log("UserDetails:");
		// console.log(userDetail);
	}, [userDetail]);

	return (
		<Navbar variant="light" expand className="navbar-bg">
			<span className="sidebar-toggle d-flex" onClick={() => setIsOpen(!isOpen)}>
				<i className="hamburger align-self-center" />
			</span>
			<span></span><a href="/"><img src={customerLogo} style={{width: '110px'}} /></a>
			<div className="d-flex justify-content-end w-100 gap-3 align-items-center ps-3" >
				<div className="d-flex justify-content-left w-100 gap-3 align-items-center" >
					<span>{`Welcome ${userDetail?.firstName ? userDetail.firstName : ''} ${userDetail?.lastName ? userDetail.lastName : ''} [${userDetail?.userName}]`}</span>	
				</div>
				<Logout />		
				<a href="/">
					<img src={Logo} style={{width: '200px', marginBottom: "-7px"}} />
				</a>
			</div>
		</Navbar>
	);
};

export default NavbarComponent;


