import React from "react";
import { RouteObject } from "react-router-dom";
import { Digicape } from "../layouts/Digicape";
import { Dash } from "../pages/digicape/ecommerce/Dash";
import { Attribute } from "../pages/digicape/ecommerce/Attribute";
import { AttributeTerm } from "../pages/digicape/ecommerce/AttributeTerm";
import { Category } from "../pages/digicape/ecommerce/Category";
import { Item } from "../pages/digicape/ecommerce/Item";
import { ItemAsset } from "../pages/digicape/ecommerce/ItemAsset";
import { ItemAttribute } from "../pages/digicape/ecommerce/ItemAttribute";
import { ItemAttributeTerm } from "../pages/digicape/ecommerce/ItemAttributeTerm";
import { ItemCategory } from "../pages/digicape/ecommerce/ItemCategory";
import { Customers } from "../pages/digicape/fixably/Customers";
import { ItemParts } from "../pages/digicape/fixably/ItemParts";
import { PurchaseInvoice } from "../pages/digicape/fixably/PurchaseInvoice";
import { PurchaseOrder } from "../pages/digicape/fixably/PurchaseOrder";
import { SalesInvoice } from "../pages/digicape/fixably/SalesInvoice";
import { VendorPurchaseOrders } from "../pages/hi-tec/tccf/VendorPurchaseOrders";
import { StockItems } from "../pages/hi-tec/tccf/StockItems";
import { HiTec } from "../layouts/HiTec";

export const HiTecRoutes: RouteObject[] = [
	{
		path: "/",
		element: <HiTec />,
		children: [
			{
				path: "",
				element: <div>:-)</div>,
			},
		],
	},
	{
		path: "tccf",
		element: <HiTec />,
		children: [
			{
				path: "vendor-purchase-orders",
				element: <VendorPurchaseOrders />,
			},
			{
				path: "stock-items",
				element: <StockItems />,
			},
		],
	}
];

