import React from "react";
import { RouteObject } from "react-router-dom";
import { Digicape } from "../layouts/Digicape";
import { Dash } from "../pages/digicape/ecommerce/Dash";
import { Attribute } from "../pages/digicape/ecommerce/Attribute";
import { AttributeTerm } from "../pages/digicape/ecommerce/AttributeTerm";
import { Category } from "../pages/digicape/ecommerce/Category";
import { Item } from "../pages/digicape/ecommerce/Item";
import { ItemAsset } from "../pages/digicape/ecommerce/ItemAsset";
import { ItemAttribute } from "../pages/digicape/ecommerce/ItemAttribute";
import { ItemAttributeTerm } from "../pages/digicape/ecommerce/ItemAttributeTerm";
import { ItemCategory } from "../pages/digicape/ecommerce/ItemCategory";
import { Customers } from "../pages/digicape/fixably/Customers";
import { ItemParts } from "../pages/digicape/fixably/ItemParts";
import { PurchaseInvoice } from "../pages/digicape/fixably/PurchaseInvoice";
import { PurchaseOrder } from "../pages/digicape/fixably/PurchaseOrder";
import { SalesInvoice } from "../pages/digicape/fixably/SalesInvoice";
import { VendorPurchaseOrders } from "../pages/hi-tec/tccf/VendorPurchaseOrders";
import { StockItems } from "../pages/hi-tec/tccf/StockItems";
import { DigiNotifications } from "../pages/digicape/fixably/DigiNotifications";

export const DigicapeRoutes: RouteObject[] = [
	{
		path: "/",
		element: <Digicape />,
		children: [
			{
				path: "",
				element: <div>:-)</div>,
			},
		],
	},
	{
		path: "dpp",
		element: <Digicape />,
		children: [
			{
				path: "claims",
				element: <div>Claims</div>,
			},
			{
				path: "devices",
				element: <div>Devices</div>,
			},
			{
				path: "dpps",
				element: <div>DPPs</div>,
			}						
		],
	},
	{
		path: "ecommerce",
		element: <Digicape />,
		children: [
			{
				path: "dash",
				element: <Dash />,
			},
			{
				path: "attribute",
				element: <Attribute />,
			},
			{
				path: "attribute-term",
				element: <AttributeTerm />,
			},
			{
				path: "category",
				element: <Category />,
			},
			{
				path: "item",
				element: <Item />,
			},
			{
				path: "item-asset",
				element: <ItemAsset />,
			},
			{
				path: "item-attribute",
				element: <ItemAttribute />,
			},
			{
				path: "item-attribute-term",
				element: <ItemAttributeTerm />,
			},
			{
				path: "item-category",
				element: <ItemCategory />,
			},
		],
	},
	{
		path: "fixably",
		element: <Digicape />,
		children: [
			{
				path: "customers",
				element: <Customers />,
			},
			{
				path: "item-parts",
				element: <ItemParts />,
			},			
			{
				path: "purchase-invoices",
				element: <PurchaseInvoice />,
			},
			{
				path: "purchase-orders",
				element: <PurchaseOrder />,
			},
			{
				path: "sales-invoices",
				element: <SalesInvoice />,
			},
			{
				path: "notifications",
				element: <DigiNotifications />,
			},			
		],

	},
	{
		path: "tccf",
		element: <Digicape />,
		children: [
			{
				path: "vendor-purchase-orders",
				element: <VendorPurchaseOrders />,
			},
			{
				path: "stock-items",
				element: <StockItems />,
			},
		],
	}
];

