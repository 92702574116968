import React from "react";
import { RouteObject } from "react-router-dom";
import { Ares } from "../layouts/Ares";


import { ShipmentsManage } from "../pages/ares/m24l/InboundShipments/ShipmentsManage";
import { ShipmentsInProgress } from "../pages/ares/m24l/InboundShipments/ShipmentsInProgess";
import { DashW } from "../pages/ares/m24l/Warehouse/DashW";

import { StockLevels } from "../pages/ares/m24l/Warehouse/StockLevels";
import { StockMovementsIn } from "../pages/ares/m24l/Warehouse/StockMovementsIn";
import { StockMovementOut } from "../pages/ares/m24l/Warehouse/StockMovementsOut";
import { DashIS } from "../pages/ares/m24l/InboundShipments/DashIS";
import { StockMaster } from "../pages/ares/m24l/Warehouse/StockMaster";
import { StockAdjustments } from "../pages/ares/m24l/Warehouse/StockAdjustments";
import { Lock } from "lucide-react";
import { Notifications } from "../pages/ares/m24l/Warehouse/Notifications";

export const AresRoutes: RouteObject[] = [
	{
		path: "/",
		element: <Ares />,
		children: [
			{
				path: "",
				element: <DashIS />,
			},
		],
	},
	{
		path: "shipments",
		element: <Ares />,
		children: [
			{
				path: "dashboard",
				element: <DashIS />,
			},
			{
				path: "manage",
				element: <ShipmentsManage />,
			},				
			{
				path: "in-progress",
				element: <ShipmentsInProgress />,
			},	
		],
	},
	{
		path: "m24l",
		element: <Ares />,
		children: [
			{
				path: "dash",
				element: <DashW />,
			},				
			{
				path: "notifications",
				element: <Notifications />,
			},			
			{
				path: "stock-adjustments",
				element: <StockAdjustments />,
			},	
			{
				path: "stock-master",
				element: <StockMaster />,
			},
			{
				path: "stock-levels",
				element: <StockLevels />,
			},						
			{
				path: "stock-movement-in",
				element: <StockMovementsIn />,
			},
			{
				path: "stock-movement-out",
				element: <StockMovementOut />,
			},
		],
	},
	{
		path: "ecommerce",
		element: <Ares />,
		children: [
			{
				path: "stock-items",
				element: <div><Lock  style={{width: "32px", height: "32px"}}/></div>,
			},
			{
				path: "sales-invoice",
				element: <div><Lock  style={{width: "32px", height: "32px"}}/></div>,
			},
		],
	},
	{
		path: "retail",
		element: <Ares />,
		children: [
			{
				path: "stock-take",
				element: <div><Lock  style={{width: "32px", height: "32px"}}/></div>,
			},
			{
				path: "receive",
				element: <div><Lock  style={{width: "32px", height: "32px"}}/></div>,
			},
		],
	}
];

