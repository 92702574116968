import { ChangeEvent, useEffect, useState } from "react";
import { CastType, IQuery, IWhere } from "../../../../component/solution/QueryList/Interfaces";
import { useAuth } from "../../../../contexts/AuthContext";
import { formatDateFull } from "../../../../utils/AppUtil";
import { Button, Card, Container, Modal } from "react-bootstrap";
import { Search, X } from "lucide-react";
import { JsonToHtmlTable } from "../../../../utils/JsonObjToTableUtil";

export const Notifications: React.FC = () => {

    const {fetchData}  = useAuth();
    const [resultElements, setResultElements] = useState<React.ReactElement[]>([]);
    const [detailElements, setDetailElements] = useState<React.ReactElement[]>([]);
    const [search, setSearch] = useState<Map<string, string>>(new Map<string, string>());
    
    // Modal Show & Close
    const [showModal, setShowModal] = useState(false);
    const [notificationData, setNotificationData] = useState<any[]>([]);
    const [recordIndex, setRecordIndex] = useState<number>(0);

    // Handled?
    const [isHandled, setIsHandled] = useState(false);
    // Function to handle checkbox change
    const handleIsHandledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsHandled(event.target.checked); 
    };    

    const [users, setUsers] = useState<Map<string, string>>(new Map<string, string>());

    const queryUrl: string = `${process.env.REACT_APP_BASE_URL}/${"query/data"}`;
    const [queryState, setQueryState] = useState<IQuery>(
        {
            tableName: "dst_notification",
            select: [

            ],
            groupBy: [],
            join: [],
            orderBy: [ 
                {
                    fullColumnName: "last_encountered",
                    direction: "DESC",
                    cast: CastType.STRING
                }
            ],
            where: [
                {
                    "fullColumnName": "handled",
                    "value": false,
                    "operator": "EQ",
                    "cast": CastType.BOOLEAN
                }                
            ],
            mainTableOnly: false,
            includeExtraColumns: true,
            includeControlColumns: true,
            modifiedAfter: null,
            touchedBefore: null,
            isDirty: null,
            pageNum: 0,
            pageSize: 50,
            frontEndOnly: null
        }        
    );


    useEffect(() => {
        const processUsers = (result: any) => {
            const users = new Map<string, string>();
            result.map((user: any) => {
                console.log(user.id);
                users.set(user.id, user.firstName !== null && user.lastName !== null ? user.firstName + " " + user.lastName : user.userName);
            });
            setUsers(users);
        }
        fetchData(`${process.env.REACT_APP_BASE_URL}/${"users/"}`, {method:"GET"}, processUsers);
    }, []);

    useEffect(() => {
        fetchMetrics();      
    }, [queryState]);

    useEffect(() => {
        setQueryState(
            {
                tableName: "dst_notification",
                select: [
    
                ],
                groupBy: [],
                join: [],
                orderBy: [ 
                    {
                        fullColumnName: "last_encountered",
                        direction: "DESC",
                        cast: CastType.STRING
                    }
                ],
                where: [
                    {
                        "fullColumnName": "handled",
                        "value": isHandled,
                        "operator": "EQ",
                        "cast": CastType.BOOLEAN
                    }                
                ],
                mainTableOnly: false,
                includeExtraColumns: true,
                includeControlColumns: true,
                modifiedAfter: null,
                touchedBefore: null,
                isDirty: null,
                pageNum: 0,
                pageSize: 50,
                frontEndOnly: null
            }            
        );
    }, [isHandled]);

    useEffect(() => {
        const searchFields = new Map<string, string>()
        searchFields.set("DocNum", "");
        searchFields.set("Name", "");
        searchFields.set("Category", "");
        searchFields.set("Description", "");
        setSearch(searchFields);        
    }, []);


    const processResult = (result: any) => {
        const notifications: any[] = result.data;
        const notificationData: any[] = [];

        for(const key in result.data){
            notificationData.push({...result.data[key].data, ...result.data[key].control, ...result.data[key].undefined});
            //console.log({...result.data[key].data, ...result.data[key].control, ...result.data[key].undefined});
        }
        setNotificationData(notificationData);

        console.log(notificationData);

        // Occurrences
        // DocNum
        // Name
        // Category
        // Description
        // Data
        // last_encountered

        // Build React elements and set state
        const resultElements: React.ReactElement[] = notificationData.map((record, index) => (
            <tr id={`${index}`} key={index}>
                
                {record?.repeat_count < 5 &&
                    <td onClick={() => showData(index)} style={{cursor: "pointer"}} className="bg-primary text-white text-center">{record?.repeat_count}</td>
                }
                {record?.repeat_count >= 5 && record?.repeat_count < 30 &&
                    <td onClick={() => showData(index)} style={{cursor: "pointer"}} className="bg-warning text-white text-center">{record?.repeat_count}</td>
                }
                {record?.repeat_count >= 30 &&
                    <td onClick={() => showData(index)} style={{cursor: "pointer"}} className="bg-danger text-white text-center">{record?.repeat_count}</td>
                }

                <td className="text-center">{record?.DocNum ? record?.DocNum : "-"}</td>
                <td>{record?.Name}</td>
                <td>{record?.category}</td>
                <td>{record?.Description}</td>
                <td style={{whiteSpace: "nowrap"}}>{formatDateFull(record?.last_encountered)}</td>
                <td style={{whiteSpace: "nowrap"}}>
                    {isHandled &&
                        <span>{users.get(record?.handled_by_id)}</span>
                    }

                    {! isHandled &&
                        <form   id={`${record?.id}`} 
                                name={`${record?.id}`} 
                                className="d-flex align-items-center" 
                                method="POST" 
                                action={`${process.env.REACT_APP_BASE_URL}/notifications/${record.id}/handle?notificationId=${record.id}`}
                                onSubmit={(e) => {e.preventDefault();}}
                                >
                            <textarea id="handled_comment" name="handled_comment" className="form-control me-2" rows={1} placeholder={`Comment...`}></textarea>

                            <button type="button" className="btn btn-primary"
                                    onClick={() => {
                                        const form = document.getElementById(`${record?.id}`) as HTMLFormElement | null;
                            
                                        if (form) {
                                            const formData = new FormData(form);
                                            const comment = formData.get("handled_comment"); // Optional: Access form data
                                    
                                            fetch(form.action, {
                                            method: form.method,
                                            body: formData,
                                            })
                                            .then((response) => {
                                            if (response.ok) {
                                                console.log("Form submitted successfully");
                                                fetchMetrics();
                                            } else {
                                                console.error("Form submission failed");
                                            }
                                            })
                                            .catch((error) => {
                                                console.error("Error submitting form", error);
                                            });
                                        }
                                    }}
                            >
                                Handle
                            </button>
                        </form> 
                    }                 
                </td>
            </tr>
        ));
        setResultElements(resultElements);
    }

    const showData = (index: number) => {
        setRecordIndex(index);
        setShowModal(true);
    } 

    const fetchMetrics = () => {
        fetchData(queryUrl, {method:"POST", body: JSON.stringify(queryState)}, processResult);
    }

    const searchClicked = () => {
        let where: IWhere[] = [];
        where.push(
            {
                "fullColumnName": "handled",
                "value": isHandled,
                "operator": "EQ",
                "cast": CastType.BOOLEAN
            }             
        );        

        search.forEach((value: string, column: string) => {
            console.log(column + " : " + value);
            if(value && value.length){
                const clause: IWhere = {
                    fullColumnName: column,
                    value: '%' + value + '%',
                    operator: "LIKE",
                    cast: CastType.STRING
                }
                where.push(clause);
            }
        });    

        const query = {...queryState}
        query.where = where;
        query.pageNum = 0;
        setQueryState(query);
        console.log(query);
    }

    const clearClicked = () => {

        const search = new Map<string, string>()
        search.set("DocNum", "");
        search.set("Name", "");
        search.set("Category", "");
        search.set("Description", "");
        setSearch(search);

        let where: IWhere[] = [];
        where.push(
            {
                "fullColumnName": "handled",
                "value": isHandled,
                "operator": "EQ",
                "cast": CastType.BOOLEAN
            }             
        );

        search.forEach((value: string, column: string) => {
            console.log(column + " : " + value);
            if(value && value.length){
                const clause: IWhere = {
                    fullColumnName: column,
                    value: '%' + value + '%',
                    operator: "LIKE",
                    cast: CastType.STRING
                }
                where.push(clause);
            }
        });  

        const query = {...queryState}
        query.where = where;
        setQueryState(query);      
    }
    
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.type === 'text') {
            setSearch(prevSearch => {
                const newSearch = new Map(prevSearch);
                newSearch.set(event.target.id, event.target.value);
                return newSearch;
            });
        }
    }    


    return (
        <>
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>
                        <Card.Title>{"Notifications"}</Card.Title>                       
                    </Card.Header>

                    <div className="ms-4 me-4" style={{}}>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td></td>                                    
                                    {Array.from(search).map(([key, value]) => (
                                        <td>
                                            <input id={key} name={key} value={value} onChange={handleOnChange} type="text" autoComplete="off" placeholder={`Search by ${key}...`} className="form-control form-control-md"></input>
                                        </td>
                                    ))}
                                    <td colSpan={2}><Search color="#c84338" onClick={searchClicked} className="custom-icon text-center align-middle" /><X color="#c84338" onClick={clearClicked} style={{width: "36px", height: "36px"}} className="custom-icon text-center align-middle ms-5"/></td>

                                </tr> 
                                <tr>
                                    <td><b>Occurrences</b></td>
                                    <td><b>DocNum</b></td>
                                    <td><b>Name</b></td>
                                    <td><b>Category</b></td>
                                    <td><b>Description</b></td>
                                    <td><b>Last Encountered</b></td>
                                    <td><b>
                                        <div className="form-check me-2">
                                            <input
                                                type="checkbox"
                                                id="handled"
                                                name="handled"
                                                className="form-check-input"
                                                checked={isHandled}
                                                onChange={handleIsHandledChange}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="handled"
                                            >
                                            Handled
                                            </label>
                                        </div>                                                                     
                                        </b>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {resultElements}
                            </tbody>
                        </table>
                    </div>
                    
                    <>
                        {/* Modal structure */}
                        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                            <Modal.Header closeButton>
                            <Modal.Title>Doc Num: {notificationData[recordIndex]?.DocNum ? notificationData[recordIndex]?.DocNum : "N/A"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <JsonToHtmlTable jsonObj={notificationData[recordIndex]} />
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </> 

                </Card>
            </Container>      
        </>
    )
}